import React, { FC, Fragment,useEffect,useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import PageHeader from '../../layouts/pageheader';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ResponsivePagination from 'react-responsive-pagination';

export default function Main_dashboard() {
  const [loading, setLoading] = useState(false);
  const [data,setData]=useState([])
  const [recnt,setRcnt]=useState([])
  const [mrData, setMrData] = useState([])
  const [pagination, setPagination] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const pageSize = 20;
  useEffect(()=>{
    dashdata();
    getMrList();
  },[]);
  const getMrList = async (value) => {   
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/MR/GetMRList?Offset=${value?.offNum ? value?.offNum : 0}&RecordCount=${pageSize}`,
      method: 'GET',
    }).then((response) => {     
      setMrData(response.data.data)
      setTotalPages(response.data.totalRecords);
    })
      .catch((error) => {
        setLoading(false)
      })
  };
  const handleAddProduct = (e) => {
    getMrList();
  }
  const changePage = (value) => {
    let temp = (value - 1) * 20;
    setPagination(temp);
    getMrList({ offNum: temp });
  };
  const ceil = Math.ceil(totalPages / pageSize);
  const dashdata=()=>{
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/api/Procurement/GetProcurementDashboard`,
      method: 'GET',
    }).then((res) => {
      const updatedRecentList = res.data.data?.recentList.map((item, index) => ({
        ...item,
        colorCode: item?.orderType === 'PO' ?   '#845adf':'#26bf94', // Example: Alternating colors
      }));
      setData(res.data.data);
      setRcnt(updatedRecentList);
    })
    .catch((error) => {
    })
  }
 
  return (
    <Fragment>
      <PageHeader title="Dashboard" />
      <Row>
        <Col xxl={4} lg={4} md={4} className='xm-4'>
          <Card className="overflow-hidden dash1-card border-0 dash1">
            <Card.Body>
              <Row>
                <Col md={4} sm={4} className="col-4">
                  <div>
                    <span className="fs-14">MR Count</span>
                    <h2 className="mb-2 mt-1 number-font carn2 font-weight-bold">{data?.mrCount}</h2>                   
                  </div>
                </Col>
              
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xxl={4} lg={4} md={4} className='xm-4'>
          <Card className="overflow-hidden dash1-card border-0 dash2">
            <Card.Body>
              <Row>
                <Col md={4} sm={4} className="col-4">
                  <div>
                    <span className="fs-14">SRV Count</span>
                    <h2 className="mb-2 mt-1 number-font carn2 font-weight-bold">{data?.srvCount}</h2>
                   
                  </div>
                </Col>
                
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xxl={4} lg={4} md={4} className='xm-4'>
          <Card className="overflow-hidden dash1-card border-0 dash3">
            <Card.Body>
              <Row>
                <Col md={4} sm={4} className="col-4">
                  <div>
                    <span className="fs-14">PO Count</span>
                    <h2 className="mb-2 mt-1 number-font carn2 font-weight-bold">{data?.poCount}</h2>                   
                  </div>
                </Col>
                
              </Row>
            </Card.Body>
          </Card>
        </Col>       
      </Row>
      <Row>
  <Col md={12} sm={12} lg={12} xl={12}>
    <Card>
      <Card.Header>
        <Card.Title>MR Status</Card.Title>
        <div className="card-options">
          <Button href="#" className="btn btn-sm" variant="primary" >
            Refresh
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="p-0 py-2">
        <div className="table-responsive">
          {loading ? (
            <Skeleton count={5} />
          ) : (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>S NO</th>
                  <th>Mr Date</th>
                  <th>Mr Number</th>
                  <th>Version</th>
                  <th>Project Name</th>
                  <th>Employee Name</th>
                  <th>Current Stage</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {mrData?.map((contact, index) => {
                  // Define color coding for `mrStage`
                  const getStageColor = (stage) => {
                    switch (stage) {
                      case "PMD Approved":
                        return "text-info"; // Light blue to indicate an approved stage
                      case "Design Approved":
                        return "text-primary"; // Blue to indicate a design stage
                      case "Operations Approved":
                        return "text-success"; // Green to signify successful operations
                      case "Ready for Enquiry Floating":
                        return "text-warning"; // Orange to show readiness for the next step
                      case "Quotations Received":
                        return "text-secondary"; // Grey to indicate a passive state
                      case "Ready for PO":
                        return "text-success fw-bold"; // Green with bold text for a final ready state
                      default:
                        return "text-muted"; // Muted grey for unknown or default stages
                    }
                  };
                  

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{contact.mrDate?.split("T")[0]}</td>
                      <td>{contact.mrNumber}</td>
                      <td>{contact.mrVersion}</td>
                      <td>{contact.projectName}</td>
                      <td>{contact.employeeName}</td>
                      <td className={getStageColor(contact.mrStage)}>
                        {contact.mrStage}
                      </td>
                      <td>{contact.mrApprovalstatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className="gridjs-footer">
            <div className="gridjs-pagination">
              <div className="gridjs-pages">
                <ResponsivePagination
                  current={pagination}
                  total={ceil}
                  onPageChange={(value) => changePage(value)}
                />
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </Col>
</Row>

      <Row className="row">       
        <Col xl={12} lg={12} md={12} sm={12}>
          <Card>
            <Card.Header>
              <Card.Title>
                Recent MR Activity
              </Card.Title>
              <div className="card-options">
                <Button href="#" className="btn btn-sm" variant='primary'>View All</Button>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              {recnt?.map((Product,index) => (
                <ul className="recent-activity" key={Math.random()}>
                  <li className="mb-5 mt-5">
                    <div>
                      <span className={`activity-timeline text-white`} style={{backgroundColor:Product.colorCode}}>{Product.orderType}</span>
                      <div className="activity-timeline-content">
                        <span className="font-weight-normal1 fs-13">{Product.number}</span><span
                          className="text-muted fs-12 float-end">{Product.approvalDate?.slice(0,10)}</span>
                        {/* <span className={`activity-sub-content text-${Product.color1} fs-11`}>{Product.orderType}</span> */}
                        <p className="text-muted fs-12 mt-1">{Product.currentStatus}</p>
                        <p className="text fs-12">Project Name : {Product.project}</p>
                        {/* <p className="text-muted fs-12 mt-1">{Product.employee}</p> */}
                      </div>
                    </div>
                  </li>

                </ul>
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      
    </Fragment>
  )
};

